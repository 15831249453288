import { render, staticRenderFns } from "./pagetwo.vue?vue&type=template&id=599d33a3&scoped=true&"
import script from "./pagetwo.vue?vue&type=script&lang=js&"
export * from "./pagetwo.vue?vue&type=script&lang=js&"
import style0 from "./pagetwo.vue?vue&type=style&index=0&id=599d33a3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "599d33a3",
  null
  
)

export default component.exports