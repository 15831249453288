<template>
    <div class="max_cont_width flex_box" @mousedown="handleonmousedown($event)">
        <div class="left_menu" v-if="isFadedIn">
            <button class="btn_Retr" @click="RetrievalResult">
                <img src="../../assets/img/search/btn_ic1.png" />
                Apply Filters
            </button>
            <el-collapse v-model="openMenu" v-loading="loadig" @change="zhankai()" ref="collapseItem">
                <el-collapse-item title="Country" name="2">
                    <template slot="title">
                        Country
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                    </template>
                    <div class="cas_panel cas_loc">
                        <input type="text" v-model="cascInptCountry" v-show="showInptCountry" class="cas_inpt" placeholder="Select Country" @input="inptCountrySearch" />
                        <el-cascader ref="Country" placeholder="Select Country" :key="cascaderKey" v-model="countryCheckList" @change="menuCheck('country')" :options="countryList" :props="{ multiple: true }" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                            <template slot-scope="{ data }">
                                <el-tooltip class="casca_lab" effect="dark" :content="data.label" placement="top-start">
                                    <!-- <div  @mouseover="onMouseOver(child.name,i)" class="line_1 coll_child_name" ><b ref="str">{{ child.name }}</b></div> -->
                                    <span class="casca_lab">
                                        <span ref="sty">{{ data.label }}</span>
                                    </span>
                                </el-tooltip>
                                <el-tooltip class="casca_count" effect="dark" :content="data.count | FilterNum" placement="top-start">
                                    <span class="casca_count">{{ data.count | FilterNum }}</span>
                                </el-tooltip>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>

                <el-collapse-item title="Industry" name="3">
                    <template slot="title">
                        Industry
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                    </template>
                    <div class="cas_panel cas_loc">
                        <input type="text" v-model="cascInptIndustry" v-show="showInptIndustry" class="cas_inpt" placeholder="Select Industry" @input="inptIndSearch" />
                        <el-cascader ref="Industry" clearable v-model="industryCheckList" :key="cascaderKey" :options="industryList" :props="optionsProps" @change="menuCheck('Industry')" placeholder="Select Industry" popper-class="cas_loc_box indystry">
                            <template slot-scope="{ data }">
                                <el-tooltip class="" effect="dark" :content="data.name" placement="top-start">
                                    <span class="casca_lab">{{ data.name }}</span>
                                </el-tooltip>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Company Type" name="4">
                    <template slot="title">
                        Company Type
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="companyTypeCheckList" @change="menuCheck('companyType')">
                            <el-checkbox v-for="(child, i) in companyTypeList" :key="i" :label="child.name">
                                <el-tooltip :disabled="isShowTooltip" class="line_1 coll_child_name" effect="dark" :content="child.name" placement="top-start">
                                    <div @mouseover="onMouseOver(child.name, i)" class="line_1 coll_child_name">
                                        <span style="font-style: none" ref="str">{{ child.name }}</span>
                                    </div>
                                </el-tooltip>
                                <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum" placement="top-start">
                                    <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                </el-tooltip>
                                <!-- <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span> -->
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Company Size" name="5">
                    <template slot="title">
                        Company Size
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="companySizeCheckList" @change="menuCheck('companySize')">
                            <el-checkbox v-for="(child, i) in companySizeList" :key="i" :label="child.name">
                                <div class="line_1 coll_child_name">{{ child.name }}</div>
                                <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                <!-- <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum " placement="top-start">
                                    <span  class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                </el-tooltip> -->
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Company Status" name="6">
                    <template slot="title">
                        Company Status
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="companyStatusCheckList" @change="menuCheck('companyStatus')">
                            <el-checkbox v-for="(child, i) in companyStatusList" :key="i" :label="child.name">
                                <div class="line_1 coll_child_name">{{ child.name }}</div>
                                <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                <!-- <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum " placement="top-start">
                                    <span  class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                </el-tooltip> -->
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Listed Company" name="7">
                    <template slot="title">
                        Listed Company
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="listedCompanyCheckList" @change="menuCheck('listedCompany')">
                            <el-checkbox v-for="(child, i) in listedCompanyList" :key="i" :label="child.name">
                                <div class="line_1 coll_child_name">{{ child.name }}</div>
                                <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                <!-- <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum " placement="top-start">
                                    <span  class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                </el-tooltip> -->
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Years in Business" name="8">
                    <template slot="title">
                        Years in Business
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="timePeriodCheckList" @change="menuCheck('timePeriod')">
                            <el-checkbox v-for="(child, i) in timePeriodList" :key="i" :label="child.name">
                                <div class="line_1 coll_child_name">{{ child.name }}</div>
                                <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                <!-- <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum " placement="top-start">
                                    <span  class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                </el-tooltip> -->
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="right_box">
            <div class="top_condition flex_center_between_box">
                <div class="condition_lab_box" style="display: flex">
                    <i class="zhankai" @click="applefilters" style="margin-right: 10px"><img src="../../assets/img/guolv.png" alt="" /></i>
                    <div :class="{ condition_labb: true, condition_lab: isActive }" class="computer_right_cont_box" @click="tabshow">
                        <template>
                            <el-tag v-for="tag in countryTag" :key="tag + '_countryTag'" @close="handleClose(tag, 'countryTag')" closable>{{ tag }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in arrProvTag" :key="tag + '_countryTag'" @close="handleClose(tag, 'arrProvTag')" closable>{{ tag }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in industryTag" :key="tag.value + '_industryTag'" @close="handleClose(tag, 'industryTag')" closable>{{ tag.name }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companyTypeTag" :key="tag.value + '_companyTypeTag'" @close="handleClose(tag, 'companyTypeTag')" closable>{{ tag.name }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companySizeTag" :key="tag.value + '_companySizeTag'" @close="handleClose(tag, 'companySizeTag')" closable>{{ tag.name }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companyStatusTag" :key="tag.value + '_companyStatusTag'" @close="handleClose(tag, 'companyStatusTag')" closable>{{ tag.name }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in listedCompanyTag" :key="tag.value + '_listedCompanyTag'" @close="handleClose(tag, 'listedCompanyTag')" closable>{{ tag.name }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in timeTag" :key="tag.value + '_timeTag'" @close="handleClose(tag, 'timeTag')" closable>{{ tag.name }}</el-tag>
                        </template>
                        <!-- <span class="clear_lab" v-if="isActive" @click="tabshow()" style="margin-right: 20px ;font-size: 16px; ">......</span> -->
                        <span class="clear_lab" @click="clearAllTags">Clear All</span>
                    </div>
                </div>
                <div class="flex_center">
                    <div class="top_ic_btn no_login" @click="showTranslate = !showTranslate">
                        <img src="../../assets/img/detail/head_ic1.png" class="no_login" />
                    </div>
                    <div class="btn_export flex_center computer_right_cont_box" @click="exportResult">
                        <img src="../../assets/img/search/export_ic1.png" />
                        Export
                    </div>
                </div>
            </div>
            <!-- Computer - table -->
            <div class="right_cont_box computer_right_cont_box">
                <div class="table_box">
                    <el-table ref="treeTable" :key="refresh" :data="parentTable" :row-class-name="getRowClass" border row-key="aaa_id" :expand-row-keys="expands" style="width: 100%" v-loading="loading">
                        <el-table-column type="expand" align="center" width="1">
                            <template slot-scope="scope">
                                <div class="tree_table_box" v-if="scope.row.branchNumber == '1'">
                                    <el-table :data="childTable" border cell-class-name="" :key="scope.row.id">
                                        <el-table-column label="Company Name" align="left" show-overflow-tooltip :resizable="false" class-name="company company_link ">
                                            <template slot-scope="scope">
                                                <div @click="setid(scope.row)" exact class="tbl_company line_1" v-show="showTranslate">
                                                    <img @click="gotocountry(scope.row)" :src="scope.row.flag" />
                                                    {{ scope.row.company_name }}
                                                </div>
                                                <div @click="setid(scope.row)" exact class="tbl_company line_1" v-show="!showTranslate">
                                                    <img @click="gotocountry(scope.row)" :src="scope.row.flag" />
                                                    {{ scope.row.company_name_en }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Location" prop="" width="150" align="left" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <div style="width: 130px" v-show="showTranslate" class="line_1">
                                                    {{ scope.row.city_cn }}
                                                    <span class="tbl_dot" v-show="scope.row.province && scope.row.province != ' ' && scope.row.province != '' && scope.row.city_cn != '' && scope.row.city_cn != ' ' && scope.row.city_cn">,</span>
                                                    {{ scope.row.province }}
                                                </div>
                                                <div style="width: 130px" v-show="!showTranslate" class="line_1">
                                                    {{ scope.row.city_en }}
                                                    <span class="tbl_dot" v-show="scope.row.province_en && scope.row.province_en != ' ' && scope.row.province_en != '' && scope.row.city_en != '' && scope.row.city_en != ' ' && scope.row.city_en">,</span>
                                                    {{ scope.row.province_en }}
                                                </div>
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Industry" width="140" prop="isic_en" align="left" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <div class="line_1" style="width: 120px">{{ scope.row.isic_en }}</div>
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Status" width="100" prop="status_en" align="left" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Type" width="120" prop="company_type_en" align="left" show-overflow-tooltip>
                                            <template slot-scope="scope">
                                                <div class="line_1" style="width: 100px">{{ scope.row.company_type_en }}</div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Size" width="80" prop="scale_en" align="right" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="" prop="operation" align="center" width="60" :resizable="false">
                                            <template slot-scope="scope">
                                                <div class="table_oper on" v-if="scope.row.isFavorite" @click="addToList(scope.row)">
                                                    <img src="../../assets/img/search/table_ic1.png" />
                                                </div>
                                                <div class="table_oper" v-else @click="addToList(scope.row)">
                                                    <img src="../../assets/img/search/table_ic2.png" />
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <template v-if="retchildren" slot="empty"><div style="padding: 20px; text-align: left; line-height: 40px">if the search results are not as you expected, please revise your search keywords and use advanced selection criteria in the left panel to enhance your search result. Meanwhile, you can contact our service team to report errors or for assistance.</div></template>

                                        <template v-else slot="empty">
                                            <div style="text-align: right; width: 110%">No Data</div>
                                        </template>
                                    </el-table>

                                    <div class="page_i_box">
                                        <Page :totalFont="true" :total="pageChild.total" :pageSize="pageChild.pageSize" @onPageChange="onChildPageChange" @currtentPageChange="currtentChildPageChange"></Page>
                                        <div class="search_rsult_txt line_2">{{ pageChild.pageNo }}-{{ pageChild.pageSize }} of over {{ pageChild.total | FilterNum }} results for "{{ searchName }}"</div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Company Name" width="385" prop="" align="left" show-overflow-tooltip :resizable="false" class-name="company company_link yangsi">
                            <template slot-scope="scope">
                                <div class="line_1" style="width: 365px">
                                    <div class="btn_expand" :class="{ expand_icon: scope.row.isExpand }" @click="onClickExpansion(scope.row)"></div>

                                    <img @click="gotocountry(scope.row)" :src="scope.row.flag" />
                                    <div @click="setid(scope.row)" class="tbl_company line_1" v-show="showTranslate">{{ scope.row.company_name }}</div>
                                    <div @click="setid(scope.row)" class="tbl_company line_1" v-show="!showTranslate">{{ scope.row.company_name_en }}</div>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="Location" width="200" prop="" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div v-show="showTranslate" style="width: 180px" class="line_1">
                                    {{ scope.row.city_cn }}
                                    <span class="tbl_dot" v-show="scope.row.province && scope.row.province != ' ' && scope.row.province != '' && scope.row.city_cn != '' && scope.row.city_cn != ' ' && scope.row.city_cn">,</span>
                                    {{ scope.row.province }}
                                </div>
                                <div v-show="!showTranslate" style="width: 180px" class="line_1">
                                    {{ scope.row.city_en }}
                                    <span class="tbl_dot" v-show="scope.row.province_en && scope.row.province_en != ' ' && scope.row.province_en != '' && scope.row.city_en != '' && scope.row.city_en != ' ' && scope.row.city_en">,</span>
                                    {{ scope.row.province_en }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Industry" prop="isic_en" align="left" show-overflow-tooltip :resizable="false" />

                        <el-table-column label="Type" width="120" prop="company_class_en" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div style="width: 100px" class="line_1">
                                    {{ scope.row.company_class_en }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="Size" width="80" :prop="showTranslate == false ? 'scale_en' : 'scale_en'" align="left" show-overflow-tooltip :resizable="false" />
                        <el-table-column label="Status" width="80" :prop="showTranslate == false ? 'status_en' : 'status_en'" align="right" show-overflow-tooltip :resizable="false" />
                        <el-table-column label="" width="55" prop="operation" align="center" :resizable="false">
                            <template slot-scope="scope">
                                <div class="table_oper on" v-if="scope.row.isFavorite" @click="dellToList(scope.row)">
                                    <img src="../../assets/img/search/table_ic1.png" />
                                </div>
                                <div class="table_oper" v-else @click="addToList(scope.row)">
                                    <img src="../../assets/img/search/table_ic2.png" />
                                </div>
                            </template>
                        </el-table-column>
                        <template v-if="ret" slot="empty"><div style="padding: 20px; text-align: left; line-height: 40px">if the search results are not as you expected, please revise your search keywords and use advanced selection criteria in the left panel to enhance your search result. Meanwhile, you can contact our service team to report errors or for assistance.</div></template>
                        <template v-else slot="empty">
                            <div style="text-align: left">
                                <noDate />
                            </div>
                        </template>
                    </el-table>
                    <div class="page_i_box">
                        <Pagetree :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Pagetree>
                        <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{ searchName }}"</div>
                    </div>
                </div>
            </div>
            <!-- phone list -->
            <div class="phone_right_cont_box line_2 phone_page_bg" style="margin-bottom: 0px">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{ searchName }}"</div>
            <div class="phone_right_cont_box">
                <div class="table_box">
                    <ul class="phone_table_box" v-loading="loading">
                        <li class="phone_table_box_item" v-for="(item, index) in parentTable" :key="index">
                            <img style="margin-top: 5px" :src="item.flag" alt="" />
                            <div class="phone_table_box_item_right">
                                <h1 class="tbl_company line_1 flex_center_between_boxphone" v-show="!showTranslate" style="display: flex; justify-content: space-between; align-items: center">
                                    <div class="tbl_company line_1" style="max-width: 255px" @click="setid(item)">{{ item.company_name_en }}</div>

                                    <div class="table_oper on phpne" v-if="item.isFavorite" @click="addToList(item)">
                                        <img src="../../assets/img/search/table_ic1.png" />
                                    </div>
                                    <div class="table_oper" v-else @click="addToList(item)">
                                        <img src="../../assets/img/search/table_ic2.png" />
                                    </div>
                                </h1>
                                <h1 class="tbl_company line_1 flex_center_between_boxphone" v-show="showTranslate" style="display: flex; justify-content: space-between; align-items: center">
                                    <div class="tbl_company line_1" style="max-width: 255px" @click="setid(item)">{{ item.company_name }}</div>

                                    <div class="table_oper on phpne" v-if="item.isFavorite" @click="addToList(item)">
                                        <img src="../../assets/img/search/table_ic1.png" />
                                    </div>
                                    <div class="table_oper" v-else @click="addToList(item)">
                                        <img src="../../assets/img/search/table_ic2.png" />
                                    </div>
                                </h1>
                                <h2 class="line_1">
                                    <b style="color: #69c867">{{ item.status_en }}</b>
                                    | {{ item.isic_en }}
                                </h2>
                                <h2 class="line_1">
                                    <div v-show="showTranslate" class="line_1">
                                        {{ item.city_cn }}
                                        <span class="tbl_dot" v-show="item.province && item.province != ' ' && item.province != '' && item.city_cn != '' && item.city_cn != ' ' && item.city_cn">,</span>
                                        {{ item.province }}
                                    </div>
                                    <div v-show="!showTranslate" class="line_1">
                                        {{ item.city_en }}
                                        <span class="tbl_dot" v-show="item.province_en && item.province_en != ' ' && item.province_en != '' && item.city_en != '' && item.city_en != ' ' && item.city_en">,</span>
                                        {{ item.province_en }}
                                    </div>
                                </h2>
                                <h2 class="line_1" @click="gotourl(item.web_site)">
                                    Website:
                                    <i style="text-decoration: underline; color: #1290c9; cursor: pointer">{{ item.web_site }}</i>
                                </h2>
                                <h2 class="line_1">
                                    <span>Sales:({{ item.update_year }}) {{ item.value_sales_en }}</span>
                                    <span style="float: right">{{ item.incorporation_date }}</span>
                                </h2>
                            </div>
                        </li>

                        <div v-if="ret && parentTable.length == 0" style="padding: 20px; text-align: left; line-height: 40px">if the search results are not as you expected, please revise your search keywords and use advanced selection criteria in the left panel to enhance your search result. Meanwhile, you can contact our service team to report errors or for assistance.</div>
                    </ul>
                </div>
                <div class="page_i_box">
                    <Pagetree :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Pagetree>
                </div>
            </div>
        </div>
        <!-- 收藏弹框 -->

        <Collection :addList.sync="addList" :fromPage="fromPage" @getCollData="getCollData" @childCloseDialog="closeDialog1(arguments)" :addPop="addPopIndex" :collecObj="collecObj"></Collection>
        <!-- 导出 -->
        <el-dialog :visible.sync="exportDialog" center :close-on-click-modal="false" custom-class="large_dialog" :append-to-body="true">
            <div class="large_detail_cont" v-loading="exportLoding">
                <div class="dialog_detail_title dialog_detail_export">Please confirm your export infromation</div>
                <div class="company_box">
                    <div class="company-head">Filter Information:</div>
                    <div>
                        <ul class="company-body">
                            <li class="company-item condition_labb">
                                <div style="width: 60px">Country :</div>

                                <el-tag v-for="tag in countryTag" :key="tag">{{ tag }}</el-tag>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width: 105px">Total Records :</div>
                                <span>{{ page.total | FilterNum }}</span>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width: 60px">Industry :</div>
                                <div style="display: flex">
                                    <el-tag v-for="tag in industryTag" :key="tag.code + '_imporindustryTagtTag'">{{ tag.name }}</el-tag>
                                </div>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width: 120px">Company Type :</div>
                                <el-tag v-for="tag in companyTypeTag" :key="tag.code + '_companyTypeTag'">{{ tag.name }}</el-tag>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width: 110px">Company Size :</div>
                                <div style="display: flex">
                                    <el-tag v-for="tag in companySizeTag" :key="tag.code + '_companySizeTag'">{{ tag.name }}</el-tag>
                                </div>
                            </li>

                            <li class="company-item condition_labb">
                                <div style="width: 120px">Company Status:</div>
                                <el-tag v-for="tag in companyStatusTag" :key="tag.code + '_companyStatusTag'">{{ tag.name }}</el-tag>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width: 110px">Listed Company:</div>
                                <el-tag v-for="tag in listedCompanyTag" :key="tag.code + '_listedCompanyTag'">{{ tag.name }}</el-tag>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width: 120px">Years in Business:</div>
                                <el-tag v-for="tag in timeTag" :key="tag.code + '_Business'">{{ tag.name }}</el-tag>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="company-head">Data Information :</div>
                <div style="color: #333333; margin-left: 15px">
                    The maximum you can export is
                    <span style="color: red">200</span>
                    records. Should you need to export entire records, please click "Request Data Service"
                    <br />
                    for further assistance.
                </div>
                <div class="btn_comp_box">
                    <download-excel class="export-excel-wrapper" :data="DetailsForm" :fields="json_fields" name="Company.xls">
                        <button class="btn_export" @click="exportCompany">Export(Max200)</button>
                    </download-excel>
                    <div class="btn_export flex_center" style="margin-left: 20px; width: 160px">
                        <a href="mailto:request@csibizinfo.com" style="color: #ffffff">Request Data Service</a>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- 高级检索弹框 -->
        <Advanced :addList.sync="parentTable" :seachName="searchName" :searchCode="searchCode" :fromPage="fromPage" @childCloseDialog2="closeDialog2(arguments)" :addPop="addPopIndex2"></Advanced>
        <Deepassment :showReport="showReportIndex" @closeReportDialog="closeDialog3($event)"></Deepassment>
        <!-- 高级检索意见 动态列展示 -->
        <el-dialog :visible.sync="showColumnSelector" center :close-on-click-modal="false" custom-class="dialog_normalsuo" :append-to-body="true">
            <el-checkbox-group v-model="selectedColumns">
                <el-checkbox v-for="(column, index) in columns" :key="index" :label="column.prop">{{ column.label }}</el-checkbox>
            </el-checkbox-group>
            <button @click="handleColumnSelection">Ok</button>
        </el-dialog>
        <!-- phone _apply afiilter -->
        <el-drawer title="" :visible.sync="drawer" size="300px" :with-header="false" direction="ltr">
            <transition name="fade" :duration="500">
                <div class="phoneappl_filters">
                    <div class="left_menu fade-content">
                        <button class="btn_Retr" @click="RetrievalResult">
                            <img src="../../assets/img/search/btn_ic1.png" />
                            Apply Filters
                        </button>
                        <el-collapse v-model="openMenu" v-loading="loadig" @change="zhankai()" ref="collapseItem">
                            <el-collapse-item title="Country" name="2">
                                <template slot="title">
                                    Country
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                                </template>
                                <div class="cas_panel cas_loc">
                                    <input type="text" v-model="cascInptCountry" v-show="showInptCountry" class="cas_inpt" placeholder="Select Country" @input="inptCountrySearch" />
                                    <el-cascader ref="Country" placeholder="Select Country" :key="cascaderKey" v-model="countryCheckList" @change="menuCheck('country')" :options="countryList" :props="{ multiple: true }" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                                        <template slot-scope="{ data }">
                                            <el-tooltip class="casca_lab" effect="dark" :content="data.label" placement="top-start">
                                                <span class="casca_lab">
                                                    <span ref="sty">{{ data.label }}</span>
                                                </span>
                                            </el-tooltip>
                                            <el-tooltip class="casca_count" effect="dark" :content="data.count | FilterNum" placement="top-start">
                                                <span class="casca_count">{{ data.count | FilterNum }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-cascader>
                                </div>
                            </el-collapse-item>

                            <el-collapse-item title="Industry" name="3">
                                <template slot="title">
                                    Industry
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                                </template>
                                <div class="cas_panel cas_loc">
                                    <input type="text" v-model="cascInptIndustry" v-show="showInptIndustry" class="cas_inpt" placeholder="Select Industry" @input="inptIndSearch" />
                                    <el-cascader ref="Industry" clearable v-model="industryCheckList" :key="cascaderKey" :options="industryList" :props="optionsProps" @change="menuCheck('Industry')" placeholder="Select Industry" popper-class="cas_loc_box indystry">
                                        <template slot-scope="{ data }">
                                            <el-tooltip class="" effect="dark" :content="data.name" placement="top-start">
                                                <span class="casca_lab">{{ data.name }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-cascader>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Company Type" name="4">
                                <template slot="title">
                                    Company Type
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                                </template>
                                <div class="coll_child">
                                    <el-checkbox-group v-model="companyTypeCheckList" @change="menuCheck('companyType')">
                                        <el-checkbox v-for="(child, i) in companyTypeList" :key="i" :label="child.name">
                                            <el-tooltip :disabled="isShowTooltip" class="line_1 coll_child_name" effect="dark" :content="child.name" placement="top-start">
                                                <div @mouseover="onMouseOver(child.name, i)" class="line_1 coll_child_name">
                                                    <span style="font-style: none" ref="str">{{ child.name }}</span>
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum" placement="top-start">
                                                <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                            </el-tooltip>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Company Size" name="5">
                                <template slot="title">
                                    Company Size
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                                </template>
                                <div class="coll_child">
                                    <el-checkbox-group v-model="companySizeCheckList" @change="menuCheck('companySize')">
                                        <el-checkbox v-for="(child, i) in companySizeList" :key="i" :label="child.name">
                                            <div class="line_1 coll_child_name">{{ child.name }}</div>
                                            <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Company Status" name="6">
                                <template slot="title">
                                    Company Status
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                                </template>
                                <div class="coll_child">
                                    <el-checkbox-group v-model="companyStatusCheckList" @change="menuCheck('companyStatus')">
                                        <el-checkbox v-for="(child, i) in companyStatusList" :key="i" :label="child.name">
                                            <div class="line_1 coll_child_name">{{ child.name }}</div>
                                            <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Listed Company" name="7">
                                <template slot="title">
                                    Listed Company
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                                </template>
                                <div class="coll_child">
                                    <el-checkbox-group v-model="listedCompanyCheckList" @change="menuCheck('listedCompany')">
                                        <el-checkbox v-for="(child, i) in listedCompanyList" :key="i" :label="child.name">
                                            <div class="line_1 coll_child_name">{{ child.name }}</div>
                                            <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Years in Business" name="8">
                                <template slot="title">
                                    Years in Business
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult?.list[1]?.power"></i>
                                </template>
                                <div class="coll_child">
                                    <el-checkbox-group v-model="timePeriodCheckList" @change="menuCheck('timePeriod')">
                                        <el-checkbox v-for="(child, i) in timePeriodList" :key="i" :label="child.name">
                                            <div class="line_1 coll_child_name">{{ child.name }}</div>
                                            <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </transition>
        </el-drawer>
    </div>
</template>
<script>
import bus from '@/components/bus';
import { searchCompany, selectGroup, delCollection } from '@/api/searchApi.js';
import { getoptions, getCompanyDetailMenuList, getIsCollect } from '../../api/companyApi';
import Page from '@/components/page';
import Pagetree from '@/components/pagetree';
import noDate from '@/components/tablenoda';
import { mixins1 } from '@/mixins/index';
import Collection from '@/components/collection';
import Advanced from '@/components/advanced';
import Deepassment from '@/components/deepassment.vue';
export default {
    mixins: [mixins1],
    components: { noDate, Page, Collection, Advanced, Deepassment, Pagetree },
    data() {
        return {
            drawer: false,
            loading: true,
            addPopIndex2: false,
            isShowTooltip: false,
            isActive: false,
            searchCode: '',
            countryxuanze: '',
            loadig: true,
            showReportIndex: false,
            arrProvTag: [],
            crylist: [],
            // openMenu:['Country','Location'],
            //token:'',
            refresh: 0,
            pageChild: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            pageNo: 1,
            pageSize: 10,
            searchName: '',
            cascaderKey: 0,
            showTranslate: false,
            expands: [],
            noMore: false, // 控制滚动禁用
            routeLoad: false, // 控制滚动禁用
            filterName: '',
            filterCountry: '',
            checkList: [],
            checkLocList: [],
            // location: '',
            // locList: [], //地区
            //表
            parentTable: [],
            childTable: [],
            addDialog: false, //添加列表弹框
            index: -1,
            addList: [],
            addPopIndex: false,
            fromPage: 'company',
            collecObj: {},
            checkAll: false,
            exportDialog: false,
            checkedCompanyList: [],
            companyList: [],
            companyTblList: [],
            branchflag: '',
            // openMenu: ['2', '3', '4', '5', '6', '7', '8'],
            openMenu: [],
            // geographicalLocation:[],
            countryPreList: [],
            countryList: [],
            countryCheckList: [],
            countryTag: [],
            countryCheck: '',

            industryPreList: [],
            industryList: [],
            industryCheckList: [],
            industryTag: [],
            industryCheck: '',

            companyTypeList: [],
            companyTypeList1: [],
            companyTypeCheckList: [],
            companyTypeTag: [],
            companyTypeCheck: '',

            companySizeList: [],
            companySizeCheckList: [],
            companySizeTag: [],
            companySizeCheck: '',

            companyStatusList: [],
            companyStatusCheckList: [],
            companyStatusTag: [],
            companyStatusCheck: '',

            listedCompanyList: [],
            listedCompanyCheckList: [],
            listedCompanyTag: [],
            listedCompanyCheck: '',

            timePeriodList: [],
            timePeriodCheckList: [],
            timeTag: [],
            timePeriodCheck: '',
            province: [],
            city: [],
            keywords: '',
            // 下载
            title: 'Company',
            json_fields: {
                'Company Name': 'company_name_en',
                Province: 'province_en',
                City: 'city_en',
                Industry: 'isic_en',
                // 'Inc.Yr.': 'incorporation_year',
                Status: 'status_en',
                Type: 'company_class_en',
                Size: 'scale_en',
            },
            DetailsForm: [],
            //权限接口获取
            powerResult: { list: [] },
            token: localStorage.getItem('token'),
            optionsProps: {
                label: 'name', //名称展示
                value: 'value', //值展示
                expandTrigger: 'click', //次级菜单的展开方式
            },
            loadPageNum: 0,

            cascInptCountry: '',
            showInptCountry: true,

            cascInptIndustry: '',
            showInptIndustry: true,
            columns: [
                // { label: 'Industry', prop: 'isic_en', align: 'right', resizable: false, visible: true ,showOverflowTooltip: true,},
                // { label: 'Type', prop: 'company_class_en', width: '110px',  resizable: false, showOverflowTooltip: true, visible: true },
                { label: 'Size', prop: 'scale_en', width: '80', resizable: false, visible: true, showOverflowTooltip: true },
                { label: 'Status', prop: 'status_en', width: '80', resizable: false, className: '', visible: true, showOverflowTooltip: true },
                { label: 'Registration', prop: 'incorporation_date', width: '90', resizable: false, visible: false, showOverflowTooltip: true },
                { label: 'Email', prop: 'e_mail', width: '80', resizable: false, visible: false, showOverflowTooltip: true },
            ],
            showColumnSelector: false,
            selectedColumns: [],
            ret: false,
            retchildren: false,
            exportLoding: false,
            isFadedIn: true,
        };
    },
    created() {
        if (!this.token) this.getPower();
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        // this.getConditions();
    },
    mounted() {
        document.querySelectorAll('.el-cascader-node__label').forEach(el => {
            el.onclick = function () {
                if (this.previousElementSibling) this.previousElementSibling.click();
            };
        });
    },
    beforeDestroy() {
        this.$off('closeReportDialog');
        window.removeEventListener('resize', this.handleResize);
    },
    updated() {
        this.$nextTick(function () {
            this.formatCC();
            //   this.showInptCountry=true
        });

        window.addEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
    },
    destroyed() {
        bus.$off('headLogFlg');
        window.removeEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
    },
    watch: {
        token: {
            handler(newVal, oldVal) {
                if (newVal) {
                    // this.openMenu = ['2', '3', '4', '5', '6', '7', '8'];
                    this.getConditions();
                    this.getPower();
                }
            },
            immediate: true,
        },
        countryList(val) {
            this.cascaderKey++;
        },
        industryList(val) {
            this.cascaderKey++;
        },
    },
    computed: {
        filteredColumns() {
            return this.columns.filter(column => column.visible);
        },
    },
    methods: {
        applefilters() {
            this.isFadedIn = !this.isFadedIn;
            if (window.innerWidth < 821) {
                this.drawer = !this.drawer;
            }
        },
        // 判断是否为phone
        handleResize() {
            if (window.innerWidth < 821) {
                // 手机端逻辑
                this.page.pageSize = 10;
            }
        },
        gotourl(row) {
            if (row.includes('http')) {
                let routeData = row;
                window.open(routeData, '_blank');
            } else {
                let routeData = 'http://' + row;
                window.open(routeData, '_blank');
            }
        },

        handleColumnSelection() {
            // 更新columns中各列的visible属性
            let that = this;
            this.$nextTick(() => {
                that.columns.forEach(column => {
                    column.visible = that.selectedColumns.includes(column.prop);
                });
            });
            this.showColumnSelector = false; // 关闭列选择器
        },

        onDragStart(scope, index) {
            this.draggingIndex = index;
        },
        onDragOver(scope, index) {
            if (this.draggingIndex !== null) {
                const draggedColumn = this.columns[this.draggingIndex];
                this.columns.splice(this.draggingIndex, 1);
                this.columns.splice(index, 0, draggedColumn);
                this.draggingIndex = index;
            }
        },
        onDragEnd() {
            this.draggingIndex = null;
        },
        gotocountry(row) {
            let routeData = this.$router.resolve({
                path: '/country?' + '&companyCountry=' + row.company_country,
            });

            window.open(routeData.href, '_blank');
        },
        zhankai() {
            let hasPower = this.powerResult.list[1].power;
            if (!hasPower) {
                this.openMenu = [];
                this.message();
            }
        },
        // 国家筛选新增添加删除
        formatCC() {
            let htmladd = document.createElement('bottom');
            let htmladd2 = document.createElement('bottom2');

            htmladd.innerHTML = 'Confirm';
            htmladd.className = 'htmladd';
            htmladd2.innerHTML = 'Delete';
            htmladd2.className = 'htmladd2';
            htmladd.style.cssText = 'cursor: pointer;position: absolute;bottom: -35px;height: 35px;line-height:35px;width:50%;background:#fff;text-align: center;color:#606266;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);';
            htmladd2.style.cssText = 'cursor: pointer;position: absolute;bottom: -35px;right: 0;height: 35px;line-height:35px;width:50%;background:#fff;text-align: center;color:#606266;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);';
            let that = this;
            var el_l = document.querySelectorAll('.el-popper.el-cascader__dropdown.ticket_ccCascader');
            if (el_l.length > 0 && el_l.length == 1) {
                var el = el_l[0];

                if (!el.querySelector('.htmladd')) {
                    el.appendChild(htmladd);
                    var bo = el.querySelectorAll('.htmladd')[0];
                    bo.onclick = function () {
                        //   this.$nextTick(() => {
                        //     that.$refs.Country.dropDownVisible = true;
                        //     })
                        that.$refs.Country.dropDownVisible = false;
                        that.getConditions();
                        // let params = {
                        //   id: that.ticketObject.id,
                        //   cc: that.ticketOrder.cc
                        // };
                        // that.$axios
                        //   .put(that.$httpServer.updateTicketOrder, params)
                        //   .then(res => {
                        //     if (res && res.data.code == "0") {
                        //       that.$message1({
                        //         message: "保存成功",
                        //         type: "success",
                        //         duration: 500
                        //       });
                        //       that.handleNodeClick(that.types, that.ticketObject.id);
                        //       that.getTicketsNum();
                        //     }
                        //   });
                    };
                }

                var el1 = el_l[0];
                if (!el1.querySelector('.htmladd2')) {
                    el1.appendChild(htmladd2);
                    var bo1 = el1.querySelectorAll('.htmladd2')[0];
                    bo1.onclick = function () {
                        that.countryCheckList = [];
                        that.countryTag = [];
                        that.province = '';
                        that.arrProvTag = [];
                        that.countryCheck = '';
                        that.$refs.Country.dropDownVisible = false;
                        that.showInptCountry = true;
                        that.getConditions();
                    };
                }
            }
        },

        // Company type 超出省略 显示
        onMouseOver(str, i) {
            const tag = this.$refs.str[i];
            const parentWidth = tag.parentNode.offsetWidth; // 获取元素父级可视宽度
            const contentWidth = tag.offsetWidth; // 获取元素可视宽度
            this.isShowTooltip = contentWidth <= parentWidth;
        },
        // ..。显示
        tabshow() {
            if (this.isActive) {
                this.isActive = false;
            } else {
                this.isActive = true;
            }
        },
        // 处理鼠标点击事件 登录权限
        handleonmousedown(e) {
            // 处理登录功能：
            // 未登录  允许点击【翻译】和【详情】
            const token = localStorage.getItem('token');
            if (!token) {
                if (!(e.target?._prevClass === 'no_login' || e.target._prevClass === 'tbl_company line_1')) {
                    // bus.$emit('headLogFlg', true); //是否显示登录
                    bus.$emit('handleShowLogin', true); //是否显示登录
                }
            }
        },
        //权限
        async getPower() {
            if (this.token) {
                this.loadig = true;
            } else {
                this.loadig = false;
            }
            let params = 'type=1';
            let res = await getCompanyDetailMenuList(params);
            if (res && res.code) {
                this.powerResult = res.data[0];
                // this.token = sessionStorage.getItem('token');
                this.searchCompanyByName();
                this.getCollData();
            }
        },
        // 点击跳转
        setid(row) {
            sessionStorage.setItem('company', JSON.stringify(row));

            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + row.aaa_id + '&companyCountry=' + row.company_country + '&companyCountrydqs=' + this.$route.query.companyCountrydqs,
            });

            window.open(routeData.href, '_blank');
        },
        //获取左边菜单
        async getConditions() {
            this.loadig = true;
            const searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            const companyCountry = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
            this.keywords = this.$route.query.keword;
            const params = {
                company_country: companyCountry,
                company_name: searchName,
                province_en: this.province,
                city_en: this.city,
                industry_en: this.industryCheck,
                company_class_code: this.companyTypeCheck,
                // searchType: this.keywords ,
                searchType: this.keywords,
                scale_code: this.companySizeCheck,
                reg_status: this.companyStatusCheck,
                listing_status_code: this.listedCompanyCheck,
                years: this.timePeriodCheck,
            };
            await getoptions(params).then(res => {
                if (res && res.code) {
                    this.loadig = false;
                    this.countryList = res.data.geographicalLocation;
                    this.industryList = res.data.industry;
                    this.industryPreList = res.data.industry;

                    this.companyTypeList = res.data.companyTypeList;

                    // this.companyTypeList=
                    this.companySizeList = res.data.companySizeList;
                    this.companyStatusList = res.data.regStatus;
                    this.listedCompanyList = res.data.listingStatusList;
                    this.timePeriodList = res.data.yearsOfEstablishment;

                    let list = this.countryList;
                    if (list.length > 0) {
                        list.map((item, index) => {
                            item.label = item.companyCountry.name;
                            item.value = item.companyCountry.value;
                            item.count = item.companyCountry.count;
                            if (item.companyProvince) {
                                item.children = item.companyProvince;
                                item.children.map(it => {
                                    it.label = it.name;
                                });
                            }
                        });
                    }
                    this.countryPreList = list;
                } else {
                    this.loadig = false;
                }
            });
        },

        //国家级联搜索不区分大小写
        inptCountrySearch() {
            this.$nextTick(() => {
                this.$refs.Country.dropDownVisible = true;
            });
            let queryString = this.cascInptCountry;
            var countryList = this.countryPreList;
            // this.$refs.Country.dropDownVisible=true
            var results = queryString ? countryList.filter(this.createFilter(queryString)) : countryList;
            this.countryList = results;
            // this.$refs.Country.dropDownVisible=true
        },
        // 过滤数据
        createFilter(queryString) {
            return countryList => {
                return countryList.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },
        //行业级联搜索不区分大小写
        inptIndSearch() {
            this.$nextTick(() => {
                this.$refs.Industry.dropDownVisible = true;
            });
            let queryString = this.cascInptIndustry;
            var industryList = this.industryPreList;

            var results = queryString ? industryList.filter(this.createFilterInd(queryString)) : industryList;
            this.industryList = results;
        },
        createFilterInd(queryString) {
            return industryList => {
                return industryList.name.toLowerCase().indexOf(queryString.toLowerCase()) > -1;
            };
        },
        //二次查询

        RetrievalResult() {
            let hasPower = this.powerResult.list[1].power;
            if (hasPower) {
                // this.getConditions();
                this.searchCompanyByName();
            } else {
                this.message();
            }

            // this.getConditions();
        },
        //搜索公司
        async searchCompanyByName() {
            this.loading = true;
            this.searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
            this.keywords = this.$route.query.keword;
            this.industryCheck = this.industryCheck == undefined ? '' : this.industryCheck;
            let params = {
                company_name: this.searchName,
                page: this.page.pageNo,
                pageSize: this.page.pageSize,
                company_country: this.searchCode,
                province_en: this.province,
                city_en: this.city,
                isic_code: this.industryCheck,
                company_class_code: this.companyTypeCheck,
                searchType: this.keywords,
                scale_code: this.companySizeCheck,
                reg_status: this.companyStatusCheck,
                listing_status_code: this.listedCompanyCheck,
                years: this.timePeriodCheck,
            };
            //    'company_name=' + this.searchName + '&company_country=' + this.searchCode + '&page=' + this.page.pageNo + '&pageSize=' + this.page.pageSize + '&province_en=' + this.province + '&city_en=' + this.city + '&isic_code=' + this.industryCheck + '&company_class_code=' + this.companyTypeCheck + '&searchType=' + this.keywords + '&scale_code=' + this.companySizeCheck + '&reg_status=' + this.companyStatusCheck + '&listing_status_code=' + this.listedCompanyCheck + '&years=' + this.timePeriodCheck
            let res = await searchCompany(params);
            this.parentTable = [];
            if (res && res.code) {
                this.loading = false;
                this.page.total = res.data.total;
                let list = (this.parentTable = res.data.rows);

                //查找本地图片
                list = list.map((item, index) => {
                    let countryImg = this.getCountryImgUrl(item.company_country);
                    let flag = require('../../../static' + countryImg);
                    this.parentTable[index].flag = flag;
                    this.refresh++;
                });
            } else {
                this.loading = false;
                // this.addPopIndex2=true
                this.parentTable = [];
                if (res.ret == '1002') {
                    this.ret = true;
                } else {
                    this.ret = false;
                }
                // this.page.total = res.data.total;
            }
        },
        //搜索子表
        async searchChildCompanyByName() {
            const params = {
                branchflag: this.branchflag,
                page: this.pageChild.pageNo,
                pageSize: this.pageChild.pageSize,
            };
            let res = await searchCompany(params);
            if (res && res.code) {
                this.pageChild.total = res.data.total;
                let list = (this.childTable = res.data.rows);

                list = list.map((item, index) => {
                    let countryImg = this.getCountryImgUrl(item.company_country);
                    let flag = require('../../../static' + countryImg);
                    this.childTable[index].flag = flag;
                });
            } else {
                this.childTable = [];
                if (res.ret == '1002') {
                    this.retchildren = true;
                } else {
                    this.retchildren = false;
                }
            }
        },
        // 判断表格是否有子项，无子项不显示展开按钮
        getRowClass(row, rowIndex) {
            // children 是你子项的数组 key
            if (row.row.branchNumber === 0) {
                return 'row-expand-cover';
            }
        },
        //菜单checkbox选择
        menuCheck(val) {
            if (val === 'country') {
                const checkedNodes = this.$refs.Country.getCheckedNodes();
                const allNodes = this.$refs.Country.getCheckedNodes(true);
                const isAllSelected = checkedNodes.length === allNodes.length;
                let arrCountry = [];
                let arrProv = [];
                let arrCountryTag = [];
                let arrCountrycheck = [];
                this.countryCheckList.forEach(item => {
                    if (isAllSelected) {
                        if (item.length == 1) {
                            if (!arrCountry.includes(item[0])) {
                                this.countryList.forEach(ite => {
                                    if (ite.value == item[0]) {
                                        arrCountryTag.push(ite.label); //展示
                                    }
                                });

                                arrCountry.push(item[0]); //传值
                            }
                        } else {
                            arrProv.push(item[1]);
                        }
                    } else {
                        if (!arrCountry.includes(item[0])) {
                            this.countryList.forEach(ite => {
                                if (ite.value == item[0]) {
                                    arrCountryTag.push(ite.label); //展示
                                }
                            });

                            arrCountry.push(item[0]); //传值
                        }
                    }

                    if (!arrCountrycheck.includes(item[0])) {
                        arrCountrycheck.push(item[0]); //传值
                    }
                });

                this.countryTag = arrCountryTag;
                this.arrProvTag = arrProv;

                this.countryCheck = arrCountrycheck.join('@@');
                this.province = arrProv.join('@@');

                //显示隐藏国家级联选择器的输入框
                if (this.countryTag.length > 0 || this.arrProvTag.length > 0) {
                    this.showInptCountry = false;
                    this.cascInptCountry = '';
                } else {
                    this.showInptCountry = true;
                }
            } else {
                if (val === 'Industry') {
                    let arr = [];
                    this.industryCheckList.forEach(item => {
                        this.industryList.forEach(it => {
                            if (it.value == item) {
                                arr.push(it);
                            }
                        });
                    });
                    this.industryTag = arr;
                    this.industryCheck = this.industryCheckList[1];
                    //显示隐藏行业级联选择器的输入框
                    if (this.industryCheckList.length > 0) {
                        this.showInptIndustry = false;
                        this.cascInptIndustry = '';
                    } else {
                        this.showInptIndustry = true;
                    }
                }
                // if (val === 'companyType') {
                let arr = [];

                this.companyTypeCheckList.forEach(item => {
                    this.companyTypeList.forEach(it => {
                        if (it.name == item) {
                            arr.push(it);
                        }
                    });
                });
                this.companyTypeTag = arr;
                let arrlist = [];
                arr.map(item => {
                    arrlist.push(item.value);
                });

                this.companyTypeCheck = arrlist.join('@@');
                // }
                // if (val === 'companySize') {
                let arr1 = [];
                this.companySizeCheckList.forEach(item => {
                    this.companySizeList.forEach(it => {
                        if (it.name == item) {
                            arr1.push(it);
                        }
                    });
                });
                let arrlist1 = [];
                arr1.map(item => {
                    arrlist1.push(item.value);
                });
                this.companySizeTag = arr1;
                this.companySizeCheck = arrlist1.join('@@');
                // this.companySizeCheck = this.companySizeCheckList.join('@@');
                // }
                // if (val === 'listedCompany') {
                let arr2 = [];
                this.listedCompanyCheckList.forEach(item => {
                    this.listedCompanyList.forEach(it => {
                        if (it.name == item) {
                            arr2.push(it);
                        }
                    });
                });
                let arrlist2 = [];
                arr2.map(item => {
                    arrlist2.push(item.value);
                });
                this.listedCompanyTag = arr2;
                this.listedCompanyCheck = arrlist2.join('@@');
                // this.listedCompanyCheck = this.listedCompanyCheckList.join('@@');
                // }
                // if (val === 'companyStatus') {
                let arr3 = [];
                this.companyStatusCheckList.forEach(item => {
                    this.companyStatusList.forEach(it => {
                        if (it.name == item) {
                            arr3.push(it);
                        }
                    });
                });
                let arrlist3 = [];
                arr3.map(item => {
                    arrlist3.push(item.value);
                });
                this.companyStatusTag = arr3;
                this.companyStatusCheck = arrlist3.join('@@');
                // this.companyStatusCheck = this.companyStatusCheckList.join('@@');
                // }
                // if (val === 'timePeriod') {
                let arr4 = [];
                this.timePeriodCheckList.forEach(item => {
                    this.timePeriodList.forEach(it => {
                        if (it.name == item) {
                            arr4.push(it);
                        }
                    });
                });
                let arrlist4 = [];
                arr4.map(item => {
                    arrlist4.push(item.value);
                });
                this.timeTag = arr4;
                this.timePeriodCheck = arrlist4.join('@@');
                // this.timePeriodCheck = this.timePeriodCheckList.join('@@');
                // }
                this.getConditions();
            }
        },
        //清除标签
        handleClose(tag, name) {
            if (name === 'countryTag') {
                this.countryTag.splice(this.countryTag.indexOf(tag), 1);
                let tagcountry = '';

                let arr = [];
                this.countryCheckList.forEach((item, index) => {
                    this.countryList.forEach(ite => {
                        if (ite.label == tag) {
                            tagcountry = ite.value;
                        }
                    });
                    if (item.includes(tagcountry)) {
                        this.countryCheckList.splice(index, 1);
                    }
                });
                if (this.countryTag.length == 0) {
                    this.searchCode = '';
                    this.countryCheck = '';
                }
                // 全选变成只选择国家
                let countryTag1 = [];

                this.countryTag.map(item => {
                    this.countryList.forEach(ite => {
                        if (ite.label == item) {
                            countryTag1.push(ite.value);
                        }
                    });
                });
                this.countryCheck = countryTag1.join('@@');
                if (countryTag1.length == 0) {
                    this.countryCheckList = [];
                }
            }
            if (name === 'arrProvTag') {
                this.arrProvTag.splice(this.arrProvTag.indexOf(tag), 1);
                this.countryCheckList.forEach((item, index) => {
                    if (item.includes(tag)) {
                        this.countryCheckList.splice(index, 1);
                    }
                });

                if (this.arrProvTag.length == 0) {
                    this.searchCode = '';
                    this.countryCheck = this.countryCheckList.join('@@');
                    // this.countryCheck = '';
                }
                this.province = this.arrProvTag.join('@@');
            }
            if (name === 'industryTag') {
                this.industryTag.splice(this.industryTag.indexOf(tag), 1);
                this.industryCheckList = [];
                this.industryCheck = '';
                //显示隐藏行业级联选择器的输入框
                if (this.industryCheckList.length > 0) {
                    this.showInptIndustry = false;
                    this.cascInptIndustry = '';
                } else {
                    this.showInptIndustry = true;
                }
            }
            if (name === 'companyTypeTag') {
                this.companyTypeTag.splice(this.companyTypeTag.indexOf(tag), 1);
                this.companyTypeCheckList.splice(this.companyTypeCheckList.indexOf(tag.value), 1);
                let arr1 = [];
                this.companyTypeTag.map(item => {
                    arr1.push(item.value);
                });
                this.companyTypeCheck = arr1.join('@@');
                // this.companyTypeCheck = this.companyTypeCheckList.join('@@');
            }
            if (name === 'companySizeTag') {
                this.companySizeTag.splice(this.companySizeTag.indexOf(tag), 1);
                this.companySizeCheckList.splice(this.companySizeCheckList.indexOf(tag.value), 1);
                // this.companySizeCheck = this.companySizeCheckList.join('@@');
                let arr2 = [];
                this.companySizeTag.map(item => {
                    arr2.push(item.value);
                });
                this.companySizeCheck = arr2.join('@@');
            }
            if (name === 'companyStatusTag') {
                this.companyStatusTag.splice(this.companyStatusTag.indexOf(tag), 1);
                this.companyStatusCheckList.splice(this.companyStatusCheckList.indexOf(tag.value), 1);
                // this.companyStatusCheck = this.companyStatusCheckList.join('@@');
                let arr3 = [];
                this.companyStatusTag.map(item => {
                    arr3.push(item.value);
                });
                this.companyStatusCheck = arr3.join('@@');
            }
            if (name === 'listedCompanyTag') {
                this.listedCompanyTag.splice(this.listedCompanyTag.indexOf(tag), 1);
                this.listedCompanyCheckList.splice(this.listedCompanyCheckList.indexOf(tag.value), 1);
                // this.listedCompanyCheck = this.listedCompanyCheckList.join('@@');
                let arr4 = [];
                this.listedCompanyTag.map(item => {
                    arr4.push(item.value);
                });
                this.listedCompanyCheck = arr4.join('@@');
            }
            if (name === 'timeTag') {
                this.timeTag.splice(this.timeTag.indexOf(tag), 1);
                this.timePeriodCheckList.splice(this.timePeriodCheckList.indexOf(tag.value), 1);
                // this.timePeriodCheck = this.timePeriodCheckList.join('@@');
                let arr5 = [];
                this.timeTag.map(item => {
                    arr5.push(item.value);
                });
                this.timePeriodCheck = arr5.join('@@');
            }
            this.getConditions();
        },
        //清除所有标签
        clearAllTags() {
            this.countryTag = [];
            this.province = '';
            this.arrProvTag = [];
            this.countryCheck = '';
            this.industryTag = [];
            this.companyTypeTag = [];
            this.companySizeTag = [];
            this.companyStatusTag = [];
            this.listedCompanyTag = [];
            this.timeTag = [];

            this.countryCheckList = [];
            this.industryCheckList = [];
            this.companyTypeCheckList = [];
            this.companySizeCheckList = [];
            this.companyStatusCheckList = [];
            this.listedCompanyCheckList = [];
            this.timePeriodCheckList = [];

            this.industryCheck = '';
            this.companyTypeCheck = '';
            this.companySizeCheck = '';
            this.companyStatusCheck = '';
            this.listedCompanyCheck = '';
            this.timePeriodCheck = '';
            this.showInptCountry = true;
            this.getConditions();
        },
        //分页
        onPageChange(pageNo) {
            let power = this.powerResult.list[5].power;
            if (power) {
                this.page.pageNo = pageNo;
                this.searchCompanyByName();
            } else {
                this.message();
            }
        },
        currtentPageChange(pageSize) {
            let power = this.powerResult.list[5].power;
            if (power) {
                this.page.pageSize = pageSize;
                this.searchCompanyByName();
            } else {
                this.message();
            }
        },

        onChildPageChange(pageNo) {
            this.pageChild.pageNo = pageNo;
            this.searchChildCompanyByName();
        },
        currtentChildPageChange(pageSize) {
            this.pageChild.pageSize = pageSize;
            this.searchChildCompanyByName();
        },
        //添加列表
        async addToList(row) {
            let power = this.powerResult.list[4].power;
            if (power) {
                let collIds = [];
                const params = {
                    crefoNo: row.aaa_id,
                };
                await getIsCollect(params).then(res => {
                    if (res.code) {
                        collIds = res.data;
                    }
                });
                this.collecObj = row;
                this.collecObj.collectionIdList = collIds;
                this.addPopIndex = true;
            } else {
                this.message();
            }
        },
        async dellToList(row) {
            let power = this.powerResult.list[4].power;
            if (power) {
                let delllsit = [];
                const params = {
                    crefoNo: row.aaa_id,
                };

                await getIsCollect(params).then(res => {
                    if (res.code) {
                        res.data.forEach(item => {
                            delllsit.push(item.id);
                        });
                    }
                });
                await delCollection(delllsit).then(res => {
                    if (res) {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'success',
                        });
                        row.isFavorite=false
                        this.getCollData()
                    }
                });
            } else {
                this.message();
            }
        },
        async getCollData() {
            await selectGroup().then(res => {
                if (res && res.code) {
                    this.addList = res.data.rows;
                }
            });
        },
        closeDialog1(value) {
            this.addPopIndex = value[0];
            this.collecObj.isFavorite = value[1];
        },
        // 新增高级检索
        closeDialog2(value) {
            this.addPopIndex2 = value[0];
        },
        closeDialog3(value) {
            this.showReportIndex = value;
        },
        //导出
        exportCompany() {
            this.DetailsForm = this.companyList;
        },

        //导出按钮
        exportResult() {
            let hasPower = this.powerResult.list[3].power;
            if (hasPower) {
                this.getRouteList();
                this.exportDialog = true;
                this.exportDialog = true;
            } else {
                this.message();
            }
        },
        // 滚动加载方法

        // 获取数据
        async getRouteList() {
            this.searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
            let params2 = {
                company_name: this.searchName,
                page: 1,
                pageSize: 100,
                company_country: this.searchCode,
                province_en: this.province,
                city_en: this.city,
                isic_code: this.industryCheck,
                company_class_code: this.companyTypeCheck,
                searchType: this.keywords,
                scale_code: this.companySizeCheck,
                reg_status: this.companyStatusCheck,
                listing_status_code: this.listedCompanyCheck,
                years: this.timePeriodCheck,
            };
            // }
            let arr1 = [];
            let arr2 = [];
            // 'company_name=' + this.searchName + '&company_country=' + this.searchCode + '&page=' + this.pageNo + '&pageSize=' + this.pageSize + '&province_en=' + this.province + '&city_en=' + this.city + '&industry_cn=' + this.industryCheckList + '&company_class_code=' + this.companyTypeCheckList + '&scale_code=' + this.companySizeCheckList + '&reg_status=' + this.companyStatusCheckList + '&listing_status_code=' + this.listedCompanyCheckList + '&years=' + this.timePeriodCheckList
            await searchCompany(params2)
                .then(res => {
                    if (res.code) {
                        this.companyList = [];
                        arr1 = res.data.rows;
                    }
                    params2.page = 2;

                    searchCompany(params2)
                        .then(Res => {
                            if (res.code) {
                                arr2 = Res.data.rows;
                            }
                            this.companyList = [...arr1, ...arr2];
                        })
                        .cath(res => {
                            this.exportLoding = false;
                        });
                })
                .catch(res => {
                    this.exportLoding = false;
                });
        },
        //模糊查询
        hanldFilterName() {
            let list = this.companyTblList;
            let keyWord = this.filterName;
            var arr = [];
            if (keyWord == '') {
                return (this.companyList = list);
            } else {
                arr = list.filter(item => {
                    return item.company_name_en.includes(keyWord);
                });
                this.companyList = arr;
            }
        },
        //弹窗全选公司
        checkedAllCompany(val) {
            this.checkedCompanyList = val ? this.companyList : [];
        },
        //展开当前关闭其他展开表格
        onClickExpansion(row) {
            if (this.expands.indexOf(row.aaa_id) < 0) {
                this.expands = [];
                this.expands.push(row.aaa_id);
                this.branchflag = row.aaa_id;

                if (row.branchNumber == '1') {
                    this.pageChild.pageNo = 1;
                    this.searchChildCompanyByName();
                }
            } else {
                this.expands = [];
            }
        },
    },
};
</script>
<style scoped>
.table_box /deep/ .el-table th > .cell {
    padding-right: 0;
    padding-left: 0;
}
.add_list {
    margin-bottom: 20px;
}
.add_list /deep/ .el-radio-button__inner {
    display: flex;
    height: 62px;
    padding: 10px 9px 8px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
}
.add_list /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #1290c9;
    color: #ffffff;
    box-shadow: none;
}

.add_list /deep/ .el-radio-button__inner:hover {
    background: #e7eaed;
}

.is-active .add_des_txt,
.is-active .add_des_title {
    color: #ffffff;
}

.add_img {
    height: 42px;
    flex: none;
}

.add_img img {
    width: 38px;
    height: 42px;
    margin-right: 9px;
}

.add_des_title {
    width: 370px;
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    line-height: 20px;
    text-align: left;
}

.add_des_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
    text-align: left;
}

.btn_return,
.btn_create {
    width: 180px;
    height: 40px;
    line-height: 40px;
    background: #e7eaed;
    border-radius: 12px;
    font-size: 16px;
    color: #022955;
}

.btn_create {
    width: 180px;
}

.btn_return {
    width: 140px;
}

.create_item_lab {
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    margin-bottom: 8px;
}

.create_item_num {
    font-size: 14px;
    font-family: 'Arial Bold';
    color: #8497ab;
}

.create_inpt {
    width: 100%;
    height: 46px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-size: 16px;
    padding: 13px 20px;
    margin-bottom: 20px;
}

.btn_comp_box {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.check_item {
    position: relative;
    max-height: 540px;
    overflow: auto;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    padding: 20px 20px 0;
    margin-bottom: 10px;
}
/* .table_box .el-table th>.cell :first-child{
    padding-left: 30px;
} */
.table_box /deep/ .el-table thead .company_link > .cell {
    padding-left: 66px !important;
}
.tree_table_box /deep/ .el-table thead .company_link > .cell {
    padding-left: 40px !important;
}
.check_item::after {
    content: '';
    position: absolute;
    left: 48%;
    top: 20px;
    bottom: 20px;
    width: 2px;
    background-color: #e7eaed;
}

.company_b_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
}

.company_box .el-checkbox {
    width: 50%;
    margin-bottom: 20px;
    margin-right: 0;
}

.company_box .comp_top_check .el-checkbox {
    margin-bottom: 10px;
    margin-left: 20px;
}

.company_box /deep/ .el-checkbox__input {
    float: left;
}
.company_box /deep/ .el-checkbox__label {
    color: #022955;
    width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 16px;
    float: left;
    padding-top: 2px;
}
.yangsi {
    padding-left: 30px !important;
}
.company_box /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #022955;
}

.company_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.company_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #022955;
    border-color: #022955;
}

.company_box /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #8497ab;
}

.company_box /deep/ .el-checkbox__inner::after {
    height: 10px;
    left: 6px;
    top: 1px;
    width: 5px;
    border-width: 2px;
}

.comp_search {
    position: relative;
    width: 300px;
    margin-bottom: 10px;
}

.comp_search .coll_search_btn {
    top: 7px;
}
.condition_lab {
    display: flex;
    flex-wrap: wrap;
}
.condition_labb {
    float: left;
    max-width: 888px;
}
.condition_labb /deep/ .el-tag {
    background-color: #ffffff;
    border-color: #e7eaed;
    height: 40px;
    line-height: 20px;
    padding: 10px;
    color: #022955;
    border-radius: 12px;
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 5px;
}
.condition_labb /deep/ .el-tag .el-tag__close {
    color: #f56b6b;
    font-weight: 600;
}
.condition_labb /deep/ .el-tag .el-tag__close:hover {
    color: #f56b6b;
    background-color: #fff;
}
.indystry .casca_lab {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 100% !important;
    /* padding-top: 10px; */
    font-size: 14px !important;
}
.table_box /deep/ .el-table__empty-block .el-table__empty-text {
    width: auto;
    /* justify-content: flex-start !important; */
}
.el-table td,
.el-table /deep/ th.is-leaf {
    border-bottom: 1px solid #e7eaed;
    border-right: none;
    padding-left: 10px;
}
.table_box /deep/ .el-table .cell {
    padding-right: 0px;
}
.hedr-icon-ydong {
    position: absolute;
    font-weight: 600;
    left: 215px;
}
/* .left_menu /deep/ .el-collapse-item__arrow{
display: none;
} */
.left_menu /deep/ .el-collapse-item__header {
    position: relative;
}

.company-head {
    /* line-height: 35px; */
    font-size: 16px;
    color: #333333;
    margin-bottom: 10px;
}
.company-body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-wrap: wrap;
    margin-left: 15px;
}
.company-item {
    width: 48%;
    line-height: 35px;
    display: flex;
    align-items: center;
    color: #333333;
    flex-wrap: wrap;
}
.company-item span {
    margin-left: 20px;
}
.company-item .el-tag {
    border: none;
    margin: 0;
    /* padding: 0; */
    padding-right: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}
.dialog_detail_export {
    text-align: center;
    border: none;
}
.phone_right_cont_box {
    display: none;
}
.phone_left_menu {
    display: none;
}
.zhankai {
    display: none;
}
@media (max-width: 821px) {
    .phone_right_cont_box {
        display: block !important;
    }
    .computer_right_cont_box {
        display: none;
    }
    .phone_right_cont_box {
        width: 100%;
        /* padding: 15px; */
        padding: 0px 10px;
        font-size: 14px;
        /* box-shadow: 0px 2px 6px 1px rgba(10,33,57,0.14); */
        background: #ffffff;
        margin-bottom: 20px;
    }

    .phone_table_box_item {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        padding: 20px 0;
        border-bottom: 1px solid #e0dce5;
    }
    .table_box .phone_table_box_item img {
        width: 25px;
        height: 15px;

        flex: none;
        margin-right: 4px;
        box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    }
    .phone_table_box_item_right {
        font-size: 14px;
        margin-left: 20px;
        width: 85%;
    }
    .phone_table_box_item_right h1 {
        font-size: 14px;
        font-weight: normal;
        height: 28px !important;
    }
    .phone_table_box_item_right h2 {
        font-size: 12px;
        color: #656373;
        font-weight: normal;
        line-height: 22px;
    }
    .line_1 {
        white-space: nowrap !important;
    }
    .phone_left_menu {
        display: block !important;
    }
    .zhankai {
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background-color: #1290c9;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .zhankai img {
        height: 18px;
        width: 18px;
    }
    .phoneappl_filters .left_menu {
        display: block !important;
    }

    .pagefixt {
        /* position: fixed;
    bottom: 0px;
    left: 3px; */
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 15px 0px;
    }
    .pagenext {
        width: 100%;
        height: 40px;
        background: #f2f5f9;
        box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    }
    .pagefixt .btn_export {
        color: #1290c9;
    }
    .top_ic_btn {
        margin-right: 0px !important;
    }
}
</style>
