<template>
  <div>
      <!-- add to List -->
      <el-dialog :visible.sync="addDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normal">
          <div v-loading="loadig" class="right_cont_box">
              <div class="table_box">
                  <el-table ref="treeTable" :data="parentTable" border style="width: 100%">
                      <!-- <el-table-column label="Company Name" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                          <template slot-scope="scope">
                              <div v-show="showTranslate" @click="setid(scope.row)" exact class="tbl_company line_1">
                                  <img style="height: 22px; width: 32px; margin-right: 5px" :src="scope.row.flag" />
                                  {{ scope.row.name }}
                              </div>
                              <div v-show="!showTranslate" @click="setid(scope.row)" exact class="tbl_company line_1">
                                  <img style="height: 22px; width: 32px; margin-right: 5px" :src="scope.row.flag" />
                                  {{ scope.row.name }}
                              </div>
                          </template>
                      </el-table-column>
                      <el-table-column label="Location" width="220" prop="registered_address_in_full,countrycode" align="center" show-overflow-tooltip :resizable="false">
                          <template slot-scope="scope">
                              <div v-show="showTranslate" class="line_1">
                                  {{ scope.row.registered_address_in_full }}
                                  ( {{ scope.row.countrycode }})
                              </div>
                              <div v-show="!showTranslate" class="line_1">
                                  {{ scope.row.registered_address_in_full }}
                                  ({{ scope.row.countrycode }})
                              </div>
                          </template>
                      </el-table-column>

                      <el-table-column label="Date" width="180" prop="incorporation_date" align="center" show-overflow-tooltip :resizable="false" />
                      <el-table-column label="Type" width="180" :prop="showTranslate == false ? 'company_type' : 'company_type'" align="center" show-overflow-tooltip :resizable="false" />

                      <el-table-column label="Status" width="80" :prop="showTranslate == false ? 'current_status' : 'current_status'" align="centers" show-overflow-tooltip :resizable="false" />
                      <template slot="empty">
                          <noDate />
                      </template> -->
                      <el-table-column v-for="(item, index) in newList" :key="`col_${index}`" :align="item.align" :prop="newList[index].prop" :label="item.label" :width="item.width" :resizable="item.resizable" :show-overflow-tooltip="item.showOverflowTooltip" :class-name="item.label == 'Company Name' ? 'company company_link yangsi no-drag-sort' : 'handle'">
                          <template slot-scope="scope">
                              <div class="line_1" v-if="item.label == 'Company Name'" style="line-height: 1">
                                  <img style="height: 22px; width: 32px; margin-right: 5px" @click.stop="gotocountry(scope.row)" :src="scope.row.flag" />
                                  <div v-show="showTranslate" @click.prevent="setid(scope.row)" exact class="tbl_company line_1">
                                      {{ scope.row.name }}
                                  </div>
                                  <div v-show="!showTranslate" @click="setid(scope.row)" exact class="tbl_company line_1">
                                      {{ scope.row.name }}
                                  </div>
                              </div>
                              <div v-else-if="item.label == 'Location'" style="line-height: 1">
                                  <div v-if="item.label" class="line_1">
                                      {{ scope.row.registered_address_in_full }}
                                      <span v-if="scope.row.registered_address_in_full != null">(</span>
                                      {{ scope.row.country }}
                                      <span v-if="scope.row.registered_address_in_full != null">)</span>
                                  </div>
                            
                              </div>
                              <div class="line_1" v-else style="line-height: 1">
                                  <div class="line_1" v-show="showTranslate">{{ scope.row[newList[index].prop] }}</div>
                                  <div class="line_1" v-show="!showTranslate">{{ scope.row[newList[index].prop] }}</div>
                              </div>
                          </template>
                      </el-table-column>
                      <template slot="empty">
                          <noDate />
                      </template>
                  </el-table>

                  <div class="page_i_box">
                      <Page :totalFont="true" :total="page.total" @onPageChange="onPageChangeList" @currtentPageChange="currtentPageChange"></Page>
                      
                      <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{ $route.query.searchname }}"</div>
                  </div>
              </div>
          </div>
      </el-dialog>

      <!-- create to List -->
  </div>
</template>
<script>
import { searchop, opDeail, hksearchop, hkDeail } from '@/api/searchApi.js';
import noDate from '@/components/tableNoDate';
import Page from '@/components/pagetwo';
import { mixins1 } from '@/mixins/index';
export default {
  mixins: [mixins1],
  components: { Page, noDate },
  props: {
      addList: {
          type: Array,
      },
      addPop: {
          type: Boolean,
          default: false,
      },
      collecObj: {
          type: Object,
      },
      fromPage: {
          type: String,
      },
  },
  data() {
      return {
          page: {
              pageNo: 1,
              pageSize: 50,
              total: 0,
          },
          parentTable: [],

          loadig: true,
          showTranslate: true,
          createDialog: false, //新增分组弹框
          newList: [],
          columns: [
              { label: 'Company Name', prop: 'company_name_en', align: 'left', width: '433', resizable: false, visible: true, showOverflowTooltip: true },
              { label: 'Location', prop: 'province_en', align: 'left', width: '210', resizable: false, visible: true, showOverflowTooltip: true },
              { label: 'Date', prop: 'incorporation_date', width: '110', resizable: false, align: 'left', visible: true, showOverflowTooltip: true },
              { label: 'Type', prop: 'company_type', width: '180', align: 'left', resizable: false, showOverflowTooltip: true, visible: true },

              { label: 'Status', prop: 'current_status', width: '115', resizable: false, className: '', visible: true, showOverflowTooltip: true },
          ],
          Honkongcolumns: [
              { label: 'Company Name', prop: 'company_name_en', align: 'left', width: '608', resizable: false, visible: true, showOverflowTooltip: true },
              // { label: 'Location', prop: 'province_en', align: 'left', width: '300px', resizable: false, visible: true, showOverflowTooltip: true },
              { label: 'ID', prop: 'id', width: '220', resizable: false, align: 'left', visible: true, showOverflowTooltip: true },
              { label: 'Status', prop: 'name_status', width: '220', resizable: false, className: '', visible: true, showOverflowTooltip: true },
              // { label: 'Type', prop: 'company_type', width: '180px', align: 'left', resizable: false, showOverflowTooltip: true, visible: true },
          ],
          Currcountry: '',
      };
  },
  computed: {
      addDialog: {
          get() {
              return this.addPop;
          },
          set(newValue) {},
      },
  },
  watch: {},
  beforeDestroy() {
      this.$EventBus.$off('deepseach');
  },

  mounted() {
      let that = this;

      this.$EventBus.$on('deepseach', item => {
          this.Currcountry = item;
          that.getAddToList(item);
      });
  },

  methods: {
      onPageChangeList(pageNo) {
          this.page.pageNo = pageNo;
          this.getAddToList(this.Currcountry);
      },
      currtentPageChange(pageSize) {
          this.page.pageSize = pageSize;
          this.getAddToList(this.Currcountry);
      },
      setid(row) {
          this.loadig = true;

          if (this.Currcountry == 'HK') {
              hkDeail(row).then(res => {
                  if (res.code) {
                      this.loadig = false;
                      let routeData = this.$router.resolve({
                          path: '/company/companysnapshot?id3a=' + res.data.aaaId + '&companyCountry=' + res.data.countryCode + '&companyCountrydqs=' + this.$route.query.companyCountrydqs,
                      });

                      window.open(routeData.href, '_blank');
                    }else{
                    this.loadig = false;
                    this.$message({
                message: res.msg,
                center: true,
                type: 'error',
              })
                  }
              });
          } else {
              opDeail(row).then(res => {
                  if (res.code) {
                      this.loadig = false;
                      let routeData = this.$router.resolve({
                          path: '/company/companysnapshot?id3a=' + res.data.aaaId + '&companyCountry=' + res.data.countryCode + '&companyCountrydqs=' + this.$route.query.companyCountrydqs,
                      });

                      window.open(routeData.href, '_blank');
                      // this.$router.push({
                      //     path: '/company/companysnapshot?id3a=' + res.data.aaaId + '&companyCountry=' + res.data.countryCode,
                      // });
                  }else{
                    this.loadig = false;
                    this.$message({
                message: res.msg,
                center: true,
                type: 'error',
              })
                  }
              });
          }
      },

      getAddToList(country) {
          let searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
          let params = {
              company_name: searchName,
              company_country: country,
              page: this.page.pageNo,
              pageSize: this.page.pageSize,
          };
          if (country == 'HK') {
            this.parentTable = [];
            this.loadig = true;
              hksearchop(params).then(res => {
                  if (res.code) {
                    this.loadig = false;
                      let data = res.data.data==undefined?[]:res.data.data;
                      this.page.total = parseInt(res.data.totalNum==undefined?0:res.data.totalNum);
                 
                      data.map(item => {
                          item.countrycode = 'HK';
                          item.country = this.getCountry(item.countrycode);
                          let countryImg = this.getCountryImgUrl(item.countrycode);
                          let flag = require('../../static' + countryImg);
                          item.flag = flag;
                      });
                      this.parentTable = data;
                      // 动态根据国家切换列表字段
                      this.newList = this.Honkongcolumns;
                  } else {
                      this.parentTable = [];
                      this.loadig = false;
                  }
              });
          } else {
              searchop(params).then(res => {
                  if (res.code) {
                      this.loadig = false;
                      let data = [];
                      this.page.total = res.data.results.total_count;
                 
                      res.data.results.companies.map(item => {
                          data.push(item.company);
                      });
                      data.map(item => {
                          item.countrycode = item.jurisdiction_code.slice(0, 2).toUpperCase();
                          item.country = this.getCountry(item.countrycode);
                          let countryImg = this.getCountryImgUrl(item.countrycode);
                          let flag = require('../../static' + countryImg);
                          item.flag = flag;
                      });
                      this.parentTable = data;
                      // 动态根据国家切换列表字段

                      this.newList = this.columns;
                  } else {
                      this.parentTable = [];
                      this.loadig = false;
                  }
              });
          }
      },

      gotocountry(row) {
          let routeData = this.$router.resolve({
              path: '/country?' + '&companyCountry=' + row.countrycode,
          });

          window.open(routeData.href, '_blank');
      },
      //添加列表

      //关闭add弹窗
      closeDialog() {
          this.page.pageNo = 1;
          this.$emit('childCloseDialog2', false);
      },
  },
};
</script>
<style scoped>
/deep/ .dialog_normal {
  width: 1100px !important;
}
.table_box /deep/ .el-table thead .company_link > .cell {
  padding-left: 44px !important;
}
</style>